function getCookies() {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        cookies[pair[0]] = unescape(pair[1]);
    }
    return cookies;
}

/**
 * Delete warning's
 * */
var deleteSwalDefaults = {
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: Lang.get('labels.confirm'),
    cancelButtonText: Lang.get('labels.cancel'),
    closeOnConfirm: true
};

function deleteWarning(self, e) {
    deleteSwalDefaults.title = Lang.get('labels.delete_question', {object: $(self).data('deleting')});
        swal(deleteSwalDefaults,
        function (isConfirm) {
            if(isConfirm) self.submit();
        });
}
function ajaxDeleteWarning(object, cb, text) {

    if(text)
        deleteSwalDefaults.text = text;

    deleteSwalDefaults.title = Lang.get('labels.delete_question', {object: object});
    swal(deleteSwalDefaults,
        function (isConfirm) {
            if(isConfirm) cb();
        });
}
function initTable(unSortable, initialOrder) {

    initialOrder = initialOrder || [ 0, "desc" ];

    window.oTable = $('#table').DataTable({
        "aoColumnDefs": [
            {"aTargets": [0]},
            {'bSortable': false, 'aTargets': unSortable}
        ],
        "oLanguage": {
            "sLengthMenu": "Show _MENU_ Rows",
            "sSearch": "",
            "oPaginate": {
                "sPrevious": "<i class='fa fa-arrow-left'></i>",
                "sNext": "<i class='fa fa-arrow-right'></i>"
            }
        },
        "aaSorting": [[1, 'asc']],
        "aLengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"] // change per page values here
        ],
        // set the initial value
        "iDisplayLength": 10,
        "order": [initialOrder]
    });

    postInitJs('#table');
}

function initAjaxTable(ajax, unSortable, initialOrder) {

    initialOrder = initialOrder || [ 0, "desc" ];

    window.oTable =  $('#table').DataTable({
        "aoColumnDefs" : [
            {"aTargets" : [0]},
            { 'bSortable': false, 'aTargets': unSortable }
        ],
        "oLanguage" : {
            "sLengthMenu" : "Show _MENU_ Rows",
            "sSearch" : "",
            "oPaginate" : {
                "sPrevious" : "<i class='fa fa-arrow-left'></i>",
                "sNext" : "<i class='fa fa-arrow-right'></i>"
            },
            sProcessing: 'Obrađujem...'
        },
        "aaSorting" : [[1, 'asc']],
        "aLengthMenu" : [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"] // change per page values here
        ],
        // set the initial value
        "iDisplayLength" : 10,
        "order": [initialOrder],
        "processing": true,
        "serverSide": true,
        ajax: ajax,
        "drawCallback": function( settings ) {
            $('.tooltips').tooltip();

            $('.delete-warn').on({
                click: function (e) {
                    e.preventDefault();
                    deleteWarning(this, e);
                },
                submit: function (e) {
                    e.preventDefault();
                    deleteWarning(this, e);
                }
            });
        }
    });

    postInitJs('#table_wrapper');
}

/**
 * Init jquery plugins on data-table html
 * */
function postInitJs(tableId){
    $( tableId + ' .dataTables_filter input').addClass("form-control input-sm").attr("placeholder", "Search");
    // modify table search input
    $( tableId + ' .dataTables_length select').addClass("m-wrap small");
    // modify table per page dropdown
    $( tableId + ' .dataTables_length select').select2();
    // initialzie select2 dropdown
    $('#table_wrapper').addClass("table-responsive");

}

function initSelectAll(){
    $('input[type="checkbox"].selectall').on('ifChecked', function(event) {
        $(this).closest("table").find(".foocheck").iCheck('check');
    }).on('ifUnchecked', function(event) {
        $(this).closest("table").find(".foocheck").iCheck('uncheck');
    });
}

//refresh csrf token after each vue http request
Vue.http.interceptors.push(function (request, next) {

    // modify request
    var xsrf = getCookies()["XSRF-TOKEN"];
    if (xsrf) {
        request.headers.set('X-XSRF-TOKEN',getCookies()["XSRF-TOKEN"]);
    }
    else {
        request.headers.set('X-CSRF-TOKEN',token);
    }

    next(function (response) {
        // modify response
        var token = response.headers.get('_token');

        if(token){
            //set new token to each hidden form input
            _.each($('input[name="_token"]'), function (value) {
                $(value).val(token);
            });

            //set global token variable to new token
            window.token = token;

            //set meta token value to new token
            $('meta[name="csrf-token"]').attr('content', token);
        }
    });

});

jQuery(document).ready(function () {
    Main.init();
    PagesGallery.init();
    window.token = $('meta[name="csrf-token"]').attr('content');

    $('.delete-warn').on({
        click: function (e) {
            e.preventDefault();
            deleteWarning(this, e);
        },
        submit: function (e) {
            e.preventDefault();
            deleteWarning(this, e);
        }
    });

    $('form').submit(function () {
    	if($(this).hasClass('ignore-loader')) return;
        $('#after-post-loader').show();
    });

    /*Bootstrap tabs, open correct tab via direct link*/
    // Javascript to enable link to tab
    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').not('.no-url').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });

    $('.just-a-menu-link').unbind();
});
